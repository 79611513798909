import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import conObj from '../utils/connection-assistant';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  RefreshJwt();

  return <>{children}</>;
}


export function RefreshJwt() {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    const decodedToken = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;

    if (decodedToken.exp < currentTime) {
      return false;
    }

    // console.log((decodedToken.exp - Date.now() / 1000) / 3600)
    // check if refresh needed, if the token left expire 10min
    if ((decodedToken.exp - Date.now() / 1000) / 3600 < 0.1) {
      conObj
        .get(`Player/Respawn`)
        .then((res) => {
          if (res.status === 200) {
            const decodedToken = jwtDecode(res.data);
            localStorage.setItem('accessToken', res.data);
            return true;
          }
          return true;
        })
        .catch((err) => {
          // unknow error
          console.error('ERROR: ', err);
        });
    }
  }
}