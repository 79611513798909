// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  shoot_numbers: `Shoot Numbers`,
  round: `Round`,
  put_a_price: `Put a price`,
  dial_numbers: `Dial Number`,
  choose_from_panel: `Choose from panel`,
  top_3: `Top 3`,
  toads_3: `3 Toads`,
  top_2: `Top 2`,
  bottom_2: `Bottom 2`,
  back_3: `Back 3`,
  back_2: `Back 2`,
  run_on: `Run On`,
  run_down: `Run Down`,
  specify_numbers: `Specify Numbers`,
  betting_conditions: `Betting Conditions`,
  minimum_bet_per_time: `Minimum bet per time: `,
  maximum_bet_per_time: `Maximum bet per time: `,
  maximum_bet_per_number: `Maximum bet per number: `,
  find_numbers: `find numbers`,
  reverse_numbers: `Reverse Numbers`,
  pay: `Pay: `,
  goals_19: `19 GOALS`,
  double_number: `DOUBLE NUMBER`,
  front_swipe: `Front swipe`,
  back_swipe: `Back swipe`,
  two_low: `two low`,
  two_tall: `two tall`,
  two_odds: `two odd`,
  two_even: `two even`,
  delete: `Delete`,
  random_numbers: `Random`,
  add_numbers: `Add Numbers`,
  enter_5_digits: `Enter 5 digits`,
  final_closing_number: `Closing accepting prize prediction numbers`,
  sum_shot_numbers: `Sum shot numbers`,
  place_16th: `16th place`,
  member_shoot_number: `Members can shoot numbers.`,
  place: `place`,
  list_of_guessers: `List of guessers`,
  look_at_duplicates: `Look at repeating numbers`,
  multiply_rate: `Multiply Rate`,
  win_rate: `Win/Lose Amount`,
  remove_duplicates: `Cut Repeating numbers`,
  all_same_prices: `All "same" prices`,
  credit_balance: `Credit Balance`,
  total_bet: `Total Bet`,
  cancel_all: `Cancel All`,
  post: `Bet Now`,
  not_enough_balance: `Not enough balance`,
  no_bet_found: `No bet(s) found`,
  place_bet_success: `Place bet success!`,
  bet_history: `Bet History`,
  confirm: `Confirm`,
  rule_title1: `Lucky draw Lottery`,
  
  rule_p1_vip: `Lucky draw lottery, Rules for betting Yi Ki VIP`,
  rule_p2_vip: `Open for online betting 88 rounds per day, prizes are issued every 15 minutes.`,
  rule_p3_vip: `The first round starts at 6:00 a.m. (Start guessing new results from 5:00 a.m.)`,
  rule_p4_vip: `Closing for the final round at 3:45 a.m.`,

  rule_p1: `Lucky draw lottery, Rules for betting Yi Ki`,
  rule_p2: `Open for online betting 288 rounds per day, prizes are issued every 5 minutes.`,
  rule_p3: `The first round starts at 6:00 a.m. (Start guessing new results from 5:00 a.m.)`,
  rule_p4: `Closing for the final round at 5:55 a.m.`,


  rule_p5: `Numbers can be sent for free unlimited number of times with clear and verifiable rules.`,
  rule_p6: `The member who shoots the 16th number will receive a free credit of 400 credit and the number 1 free of 200 credit, but must have a minimum bet of 100 credit for that round.`,
  rule_title2: `Top running numbers, guess the top 3 results like a government lottery`,
  rule_p7: `If the number of members participating in the prediction is less than 20 ranks, in any case The system will Refund and balance who thrust into that round immediately`,
  rule_p8: `!!If the system crashes or crashes During the period before the closure of participation in sending guessing prize numbers until causing members to be unable to access the system, the team will judge the prize draw as appropriate.`,
  rule_p9: `(Judging is at the discretion of the team. The team will judge with the most transparency and fairness)`,
  rule_p10: `Before the participation time expires, it results in predicting the winning numbers at 1 minute, the system has a problem and cannot be accessed. causing you to be unable to
  participate in guessing the numbers In this case, the team will cancel and refund the credit in that round immediately. Although before that there were more than 20 members shooting in the order.`,
  rule_p11: `(The issuance of prizes is calculated from the prize results that every member submits a 5-digit number [12345], then the team will bring together all the numbers sent by members in that round and take
    the sum that has been brought. to subtract with the 16th number of that round and the results obtained will be drawn as prizes)`,
  rule_p12: `Example of issuing prizes The sum of all number shots is 1234567890 – [51876 example number 16] = 1234516014 << result after subtracting number 16.`,
  rule_p13: `The total of the last 3 digits will be drawn as the top 3 numbers of the Yi Ki lottery round, then the sum of thousands and tens of thousands will be drawn as the bottom 2 numbers of the Yi Ki lottery round.`,
  rule_p14: `According to the example, the sum after subtracting the 16th order = 12345[16][014] = The top 3 winning numbers are = [014] The bottom 2 winning numbers are = [16] The member who can shoot the 16th number. Get free credit 400`,
  rule_p15: `special!! The member who shoots the 1st number will receive a free credit of 200 credit.`,
  rule_p16: `(Conditions must have a minimum bet for that round of 100 credit or more)`,
  // rule_p17: `Lottery Yi Ki is open to bet 88 rounds per day.`,
  // rule_p18: `Give out prizes every 15 minutes.`,
  // rule_p19: `Open the first round 06:00 – 03:45 after the Yi Ki lottery closes for each round.`,
  rule_p20: `The number shooting system opens for firing 2 minutes after the number shooting system closes for another 1 minute.`,
  rule_p21: `The system will check the statement, which takes 2-5 minutes to pay.`,
  bet_result: `Bet Result`,
  time_left: `Time left for the {{x}} prize draw`,
  type: `Type`,
  bet_type: 'Bet Type',
  result: `Result`,
  draw_date: `Draw Date`,
  no_result: `No Result`,
  bet_number: `Bet Number`,
  amount: `Amount`,
  closing_date_time: `Closing Date/Time`,
  closed_at: `Closed At`,
  draw_ended: `Draw Ended`,
  refunded: `Refunded?`,
  draw_ended_message: `Draw has ended. Please wait for the result.`,


  not_accepting_bets: `Not accepting bets!`,
  closing_at: `Closing at`,
  join_now: `Join Now!`,
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
