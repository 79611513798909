import PropTypes from 'prop-types';
import { Button } from '@mui/material';

GradientButton.propTypes = {
  label: PropTypes.string.isRequired,
  fontFamily: PropTypes.string,
  sx: PropTypes.object,
  clickEvent: PropTypes.func.isRequired,
  textColor: PropTypes.string,
  isBtnDisabled: PropTypes.bool,
};
export default function GradientButton({
  label,
  clickEvent,
  bgColor = '#434343, #434343',
  gradientColors = '#383200, #FFE600, #3C3600',
  borderWidth = 1,
  sx,
  isBtnDisabled,
}) {
  return (
    <Button
      sx={{
        background: `linear-gradient(${bgColor}) padding-box,linear-gradient(to bottom, ${gradientColors}) border-box!important`,
        p: '10px',
        width: '100%',
        fontSize: '.8rem',
        fontFamily: 'Inter',
        fontWeight: 400,
        lineHeight: 1,
        color: 'white',
        borderRadius: '10px',
        border: `${borderWidth}px solid transparent`,
        boxShadow: '0px 0px 4px 0px #FF9900',
        ...sx,
      }}
      onClick={isBtnDisabled ? {} : clickEvent}
    >
      {label}
    </Button>
  );
}
