import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import homeBannerEn from '../assets/game_selection/home_banner_en.png';
import homeBannerTh from '../assets/game_selection/home_banner_th.png';
import homeBannerMy from '../assets/game_selection/home_banner_my.png';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const _locale = localStorage.getItem('epsilon')?.toLowerCase();
  let _homeBanner = homeBannerEn;
  if (_locale === 'th') {
    _homeBanner = homeBannerTh;
  }
  if (_locale === 'my') {
    _homeBanner = homeBannerMy;
  }
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
      ],
    },

    {
      path: '/',
      element: (
        <AuthGuard>
          <div style={{ background: 'rgb(11, 6, 3)' }}>
            <img
              src={_homeBanner}
              alt="Home Banner"
              style={{ maxWidth: '1200px', width: '100%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
            />
          </div>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <Home /> },
      ],
    },

    // Games Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'games/lottery', element: <LotteryList /> },
        { path: 'games/bet', element: <Bet /> },
        { path: 'games/bet/shoot', element: <ShootNumberPage /> },
        { path: 'home/bethistory', element: <BetHistory /> },
        { path: 'home/rules', element: <Rules /> },
        { path: 'home/history', element: <PastResult /> },
      ],
    },

    // {
    //   path: '/',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: 'calendar', element: <Calendar /> },
    //   ],
    // },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// HOME
const Home = Loadable(lazy(() => import('../views/games/home/Home')));
const BetHistory = Loadable(lazy(() => import('../views/games/BetHistory')));
const Rules = Loadable(lazy(() => import('../views/games/Rules')));
const PastResult = Loadable(lazy(() => import('../views/games/PastResult')));

// BET
const LotteryList = Loadable(lazy(() => import('../views/games/LotteryList')));
const Bet = Loadable(lazy(() => import('../views/games/Bet')));
const ShootNumberPage = Loadable(lazy(() => import('../views/games/ShootNumberPage')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
