import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Box, Container, Dialog, Grid, Stack, Typography } from '@mui/material';
import conObj from '../../../utils/connection-assistant';
import useLocales from '../../../hooks/useLocales';
import Image from '../../../components/Image';
import GradientButton from '../../../components/buttons/GradientButton';
import { fCurrency } from '../../../utils/formatNumber';

const DrawResultDialog = ({
  openDrawResult,
  setOpenDrawResult,
  winDialogAnimate,
  setWinIconPosition,
  setStandAloneWinIcon,
}) => {
  const { translate } = useLocales();
  /**
   * State Declaration -------------------------------------------------------------------------------------------------------
   */
  const [selectedDraw, setSelectedDraw] = useState({});
  const [page, setPage] = useState(0);
  const [top2, setTop2] = useState('');

  const myRef = useRef();

  useEffect(() => {
    getSelectedDraw(openDrawResult?.drawId);
  }, []);

  useLayoutEffect(() => {
    if (myRef?.current) {
      // console.log(myRef?.current);
      // // X
      // const x = myRef.current.offsetLeft;

      // // Y
      // const y = myRef.current.offsetTop;
      // console.log(`x = ${x} | y = ${y}`);
      const winnerIcon = document.querySelector('#winner-icon');
      const winnerIconPos = winnerIcon?.getBoundingClientRect();
      // console.log(`${JSON.stringify(winnerIconPos)}`);
      setWinIconPosition({
        x: winnerIconPos.x,
        y: winnerIconPos.y,
      });
    }
  }, [myRef?.current]);

  /**
   * Private methods ---------------------------------------------------------------------------------------------------------
   */
  const getSelectedDraw = (currentRound) => {
    conObj
      .get(`Draw/${currentRound}`)
      .then((res) => {
        if (res.status === 200) {
          setSelectedDraw(res.data);
          // const dummy = {
          //   closeAt: '2023-08-17T06:20:00Z',
          //   finalSum: 251786276,
          //   n16Shoot: 121212,
          //   result: 123456,
          //   resultTop3: 345,
          //   resultBottom2: 56,
          //   utcDate: '2023-08-16',
          //   isRefund: false,
          //   closed: true,
          // };
          // setSelectedDraw(dummy);
          // const drawResult = (res.data?.finalSum - parseInt(res.data?.n16Shoot, 10)).toString();
          const drawResult = res.data?.result;
          setTop2(drawResult.slice(-2));
        }
      })
      .catch((err) => {
        console.log(`Error: ${err.message}`);
      })
      .then(() => {
        //
      });
  };

  const buildResult = () => {
    if (!selectedDraw.result) {
      return (
        <Typography textAlign="center" sx={{ border: '1px solid' }}>
          {translate('no_result')}
        </Typography>
      );
    }

    const drawResult = (selectedDraw.finalSum - parseInt(selectedDraw.n16Shoot, 10)).toString();
    const unusedDigits = drawResult.substring(drawResult.length - 5, drawResult.length - 6);
    const bot2 = drawResult.substring(drawResult.length - 5, drawResult.length - 3);
    const top3 = drawResult.substring(drawResult.length - 3, drawResult.length);
    return (
      <Stack
        spacing={{ xl: 3, lg: 3, md: 3, sm: 3, xs: 1 }}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 'fit-content',
          p: '5px 24px',
          borderRadius: '43px',
          //   background:
          //     'linear-gradient(rgb(28, 28, 28), rgb(28, 28, 28))    padding-box,linear-gradient(to bottom, #383200, #FFE600, #3C3600)    border-box',
          //   boxShadow: '0px 0px 0px 1px #3C3600',
          //   border: '1px solid transparent',
        }}
        className="gold-brown-gradient"
      >
        {`${unusedDigits}${bot2}`.split('').map((item) => (
          <Typography
            sx={{
              color: '#FFC24A',
              fontSize: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '5.3vw' },
              fontFamily: 'Inter',
              fontWeight: 800,
            }}
          >
            {item}
          </Typography>
        ))}
        {top3.split('').map((item) => (
          <Typography
            sx={{
              color: 'white',
              fontSize: { xl: '2rem', lg: '2rem', md: '2rem', sm: '2rem', xs: '5.3vw' },
              fontFamily: 'Inter',
              fontWeight: 800,
            }}
          >
            {item}
          </Typography>
        ))}
      </Stack>
    );
  };

  return (
    <Dialog
      open={openDrawResult?.open}
      fullWidth
      maxWidth="sm"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setOpenDrawResult({ open: false, drawId: '', data: null });
        }
      }}
      PaperProps={{
        style: {
          background:
            'linear-gradient(rgb(19, 19, 19), rgb(19, 19, 19)) padding-box,linear-gradient(to bottom, rgba(255, 181, 36, .5), white, rgba(255, 181, 36, .5)) border-box',
          border: '2px solid transparent',
          margin: '16px',
        },
      }}
      sx={{ opacity: '0' }}
      disableEscapeKeyDown
      // className={winDialogAnimate && 'win-dialog'}
      className="win-dialog"
    >
      <Container maxWidth="lg" sx={{ p: { xl: 2, lg: 2, md: 2, sm: 2, xs: 2 } }} ref={myRef}>
        <Box sx={{ pt: 2, pb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', opacity: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: { xl: '70%', lg: '70%', md: '70%', sm: '70%', xs: '85%' },
                borderRadius: '40px',
                position: 'relative',
                p: '5px',
              }}
              className="gold-brown-gradient"
            >
              <img
                src={require('../../../assets/game_selection/winner_icon.png')}
                alt="Winner Icon"
                style={{
                  width: '60px',
                  // width: { xl: '60px', lg: '60px', md: '60px', sm: '60px', xs: '45px' },
                  height: 'fit-content',
                  position: 'absolute',
                  left: '-10px',
                  visibility: 'hidden',
                }}
                id="winner-icon"
                className="winner-icon"
              />
              <Typography
                sx={{
                  fontSize: { xl: '1.5rem', lg: '1.5rem', md: '1.5rem', sm: '1.5rem', xs: '3.9vw' },
                  fontFamily: 'Inter',
                  fontWeight: 400,
                }}
              >
                {fCurrency(
                  openDrawResult?.data?.wons?.map((item) => item.winAmount).reduce((prev, curr) => prev + curr, 0)
                )}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" sx={{ mt: '40px' }}>
            <Stack
              direction="row"
              sx={{ width: '100%', position: 'relative', justifyContent: 'center', alignItems: 'center' }}
            >
              <Image
                sx={{
                  height: 'fit-content',
                  width: { xl: '150px', lg: '150px', md: '150px', sm: '150px', xs: '30vw' },
                  display: 'block',
                }}
                src={
                  openDrawResult?.gameName?.toUpperCase().indexOf('VIP') !== -1
                    ? require('../../../assets/bet/bet_header_vip.png')
                    : require('../../../assets/bet/bet_header_yiki.png')
                }
                alt="Game Logo"
              />
              <GradientButton
                label={`${translate('round')} ${openDrawResult?.data.drawLot}`}
                clickEvent={() => {}}
                bgColor="rgb(28, 28, 28), rgb(28, 28, 28)"
                sx={{
                  width: 'fit-content',
                  borderRadius: '26px',
                  ml: 2,
                  fontSize: { xl: '1.3rem', lg: '1.3rem', md: '1.3rem', sm: '1.3rem', xs: '3.5vw' },
                  p: '5px 15px',
                }}
              />
              {/* <Button
                onClick={() => setOpenDrawResult({ open: false, gameName: '', gameCode: '', drawLot: '', drawId: '' })}
                sx={{ p: 0, position: 'absolute', top: 0, right: 0 }}
              >
                <Image src={require('../../assets/bet/close_button.png')} alt="Back button" sx={{ width: '30px' }} />
              </Button> */}
            </Stack>
          </Box>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>{buildResult()}</Box>

          <Grid container sx={{ mt: 5 }}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Stack direction="column" justifyContent="center" alignItems="center">
                <GradientButton
                  label={translate('top_3')}
                  clickEvent={() => {}}
                  bgColor="rgb(28, 28, 28), rgb(28, 28, 28)"
                  sx={{
                    borderRadius: '21px',
                    fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '3.5vw' },
                    width: { xl: '120px', lg: '120px', md: '120px', sm: '120px', xs: '23vw' },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '3.5vw' },
                    fontFamily: 'Digital Numbers',
                    fontWeight: 400,
                    color: 'white',
                    mt: 3,
                  }}
                >
                  {selectedDraw?.resultTop3}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Stack direction="column" justifyContent="center" alignItems="center">
                <GradientButton
                  label={translate('top_2')}
                  clickEvent={() => {}}
                  bgColor="rgb(28, 28, 28), rgb(28, 28, 28)"
                  sx={{
                    borderRadius: '21px',
                    fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '3.5vw' },
                    width: { xl: '120px', lg: '120px', md: '120px', sm: '120px', xs: '23vw' },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '3.5vw' },
                    fontFamily: 'Digital Numbers',
                    fontWeight: 400,
                    color: 'white',
                    mt: 3,
                  }}
                >
                  {top2}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Stack direction="column" justifyContent="center" alignItems="center">
                <GradientButton
                  label={translate('bottom_2')}
                  clickEvent={() => {}}
                  bgColor="rgb(28, 28, 28), rgb(28, 28, 28)"
                  sx={{
                    borderRadius: '21px',
                    fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '3.5vw' },
                    width: { xl: '120px', lg: '120px', md: '120px', sm: '120px', xs: '23vw' },
                  }}
                />
                <Typography
                  sx={{
                    fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '1rem', xs: '3.5vw' },
                    fontFamily: 'Digital Numbers',
                    fontWeight: 400,
                    color: 'white',
                    mt: 3,
                  }}
                >
                  {selectedDraw?.resultBottom2}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <GradientButton
            label={translate('confirm')}
            clickEvent={() => {
              setOpenDrawResult({ open: false, drawId: '', data: null });
              setStandAloneWinIcon();
            }}
            sx={{
              p: { xl: '15px 5px', lg: '15px 5px', md: '15px 5px', sm: '15px 5px', xs: '10px 5px' },
              fontSize: { xl: '1.1rem', lg: '1.1rem', md: '1.1rem', sm: '1.1rem', xs: '3.5vw' },
              borderRadius: '10px',
              width: { xl: '200px', lg: '200px', md: '200px', sm: '200px', xs: '33vw' },
              display: 'flex',
              mx: 'auto',
              mt: 5,
            }}
            bgColor="to right, rgb(26, 21, 17), rgb(55, 30, 16), rgb(26, 21, 17)"
          />
        </Box>
      </Container>
    </Dialog>
  );
};
export default DrawResultDialog;
