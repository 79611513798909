import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Grid, Dialog, Stack, AppBar, Toolbar, Typography } from '@mui/material';
import { m } from 'framer-motion';
import { isSafari } from 'react-device-detect';
// import { zoomOutUp, zoomOutLeft } from 'react-animations';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import conObj from '../../../utils/connection-assistant';
import { useDispatch, useSelector } from '../../../redux/store';
import { updateAccount } from '../../../redux/slices/account';
import Image from '../../../components/Image';
import DrawResultDialog from './DrawResultDialog';
import { fCurrency } from '../../../utils/formatNumber';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  position: 'absolute',
  width: 'fit-content',
  height: 'fit-content',
  display: 'block',
  marginTop: '5rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  borderRadius: '15px !important',
  // [theme.breakpoints.up('xl')]: {
  //   marginLeft: '0 !important',
  //   marginRight: '0 !important',
  // },
  // [theme.breakpoints.up('lg')]: {
  //   marginLeft: '0 !important',
  //   marginRight: '0 !important',
  // },
  // [theme.breakpoints.down('md')]: {
  //   marginLeft: '0 !important',
  //   marginRight: '0 !important',
  // },
  // [theme.breakpoints.down('sm')]: {
  //   marginLeft: '0 !important',
  //   marginRight: '0 !important',
  // },
  // [theme.breakpoints.down('xs')]: {
  //   marginLeft: '0 !important',
  //   marginRight: '0 !important',
  // },
}));

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  display: 'block',
  position: 'inherit !important',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '#392600',
  // boxShadow: '0px 0px 50px -15px #ffd800',
  borderBottom: '2px solid #FFC85C',
  height: 'fit-content',
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   // ...(isCollapse && {
  //   //   width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   // }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   // ...(verticalLayout && {
  //   //   width: '100%',
  //   //   height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   //   backgroundColor: theme.palette.background.default,
  //   // }),
  // },
}));

const RooToolbar = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '10px 2rem',
  // height: '94px',
  [theme.breakpoints.up('sm')]: {
    padding: '10px',
  },
  [theme.breakpoints.up('xs')]: {
    padding: '10px',
  },

  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const winnerAnimationTimeout = useRef(null);

  const { balance, notifs } = useSelector((state) => state.account);

  const [playerInfo, setPlayerInfo] = useState({});
  const [openNotification, setOpenNotification] = useState(false);
  const [openWinBet, setOpenWinBet] = useState(true);
  const [winBetDrawNumber, setWinBetDrawNumber] = useState(0);
  const [winBetInfo, setWinBetInfo] = useState({
    open: true,
    data: null,
  });
  const [openWinAnimation, setOpenWinAnimation] = useState(false);
  const [openDrawResult, setOpenDrawResult] = useState({
    open: false,
    drawId: '',
    data: null,
  });
  const [winDialogAnimate, setWinDialogAnimate] = useState(false);
  const [winIconPosition, setWinIconPosition] = useState(null);
  const [winStyle, setWinStyle] = useState({});
  const [standAloneWinIcon, setStandAloneWinIcon] = useState(false);
  const screenWidth = window.innerWidth;

  useEffect(() => {
    getPlayerInfo();

    const interval = setInterval(() => {
      getPlayerInfo();
    }, 15000);

    // Enble this to manually trigger winner popup animation, for testing only
    // drawId must be valid for today
    // getDrawWinBetInfo(114535);

    return () => {
      clearInterval(interval);
      clearTimeout(winnerAnimationTimeout?.current);
    };
  }, []);

  useEffect(() => {
    if (openDrawResult.open && winIconPosition) {
      setWinStyle({
        y: winIconPosition?.y - (screenWidth > 400 ? 60 : 50),
        x: winIconPosition?.x,
      });
    }
  }, [winIconPosition]);

  const getPlayerInfo = () => {
    conObj.get('Player/Me').then((res) => {
      if (res.status === 200) {
        setPlayerInfo(res.data);
        const { balance, playerName, notifs } = res.data;
        handleUpdateAccount({ balance, notifs });
        localStorage.setItem('playerName', playerName);
        if (notifs) {
          const hasWinnings = notifs?.find((f) => !f.isRead && f.title === 'Won Bet');
          if (hasWinnings) {
            /**
             * - Show Win animation, 1s duration
             * - also paint the dialog already with opacity 0
             */
            setOpenWinAnimation(true);

            /**
             * after show win animation, set opacity animation from 0 -> .5 -> 1
             */

            /**
             * - Then show dialog after animation finished, 2s mark
             *
             */
            winnerAnimationTimeout.current = setTimeout(() => {
              getDrawWinBetInfo(hasWinnings.drawId);
              setOpenWinAnimation(false);

              // immediately call this so that poup wont show again
              readNotifications();
            }, 1100);
          }
        }
      }
    });
  };

  const handleUpdateAccount = (accInfo) => {
    dispatch(updateAccount(accInfo));
  };

  const readNotifications = () => {
    conObj.get('Player/ReadNotifs').then((res) => {
      if (res.status === 200) {
        //
      }
    });
  };

  const getDrawWinBetInfo = (_drawId) => {
    conObj.get(`Player/fromNotif/${_drawId}`).then((res) => {
      if (res.status === 200) {
        // setWinBetInfo((prevState) => ({ open: true, drawId: _drawId, data: res.data }));

        setOpenDrawResult((prevState) => ({ open: true, drawId: _drawId, data: res.data }));

        // setOpenDrawResult((prevState) => ({
        //   open: true,
        //   drawId: _drawId,
        //   // data: res.data,
        //   data: {
        //     drawDateUtc: '11-11-2011',
        //     drawDate: '11-11-2011',
        //     drawLot: '19213',
        //     wons: [
        //       {
        //         betNumber: '123',
        //         betType: 'TOP3',
        //         betAmount: '1',
        //         winAmount: 8000.1,
        //       },
        //       {
        //         betNumber: '321',
        //         betType: 'TOP2',
        //         betAmount: '1',
        //         winAmount: 8000.1,
        //       },
        //     ],
        //   },
        // }));

        setOpenWinAnimation(true);

        setTimeout(() => {
          setOpenWinAnimation(false);
          setStandAloneWinIcon(true);
        }, 2500);
      }
    });
  };

  return (
    <>
      <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
        <RooToolbar
          sx={{
            minHeight: '100% !important',
            // px: { lg: 5 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          {/* {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="noto-v1:money-bag" />
          </IconButtonAnimate>
        )} */}

          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Image
                src={require('../../../assets/app_logo.png')}
                alt="Jumboo YK"
                sx={{
                  height: 'fit-content',
                  width: { xl: '200px', lg: '200px', md: '200px', sm: '35vw', xs: '35vw' },
                  // position: 'absolute',
                  // top: { xl: '-10px', lg: '-10px', md: '-10px', sm: '-2vw', xs: '-2vw' },
                  // left: 0,
                }}
              />
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={6}
              sx={{
                display: 'flex',
                justifyContent: 'end',
                // mt: { xl: 0, lg: 0, md: 2, sm: 2, xs: 2 },
              }}
            >
              <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Stack
                    className="top-bottom-gradient"
                    direction="row"
                    sx={{
                      background: 'rgb(54, 54, 54)',
                      alignItems: 'center',
                      // border: '2px solid orange',
                      borderRadius: '30px',
                      py: '5px',
                      pr: '60px',
                      pl: '10px',
                      position: 'relative',
                    }}
                  >
                    <img src={require('../../../assets/coin.png')} alt="Coin" />
                    <Typography sx={{ ml: '10px', fontSize: '32px', fontWeight: 600 }}>{balance}</Typography>
                  </Stack>
                  <Box className="top-bottom-gradient-1" sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        p: { xl: 2, lg: 2, md: 2, sm: '2vw', xs: '2vw' },
                        // border: '2px solid orange',
                        borderRadius: '45px',
                        background: 'rgb(54, 54, 54)',
                        ml: '-30px',
                        position: 'relative',
                      }}
                    >
                      <img src={require('../../../assets/wallet.png')} alt="Wallet" />
                    </Box>
                  </Box>
                </Box> */}

                <Stack direction="column">
                  <Stack direction="row" alignItems="center">
                    <Image
                      src={require('../../../assets/account_icon.png')}
                      sx={{
                        width: { xl: '25px', lg: '25px', md: '25px', sm: '4vw', xs: '4vw' },
                        height: { xl: '25px', lg: '25px', md: '25px', sm: '4vw', xs: '4vw' },
                      }}
                      alt="Account"
                    />
                    <Typography
                      sx={{
                        ml: 1,
                        fontFamily: 'Inter',
                        fontWeight: 400,
                        fontSize: { xl: '1rem', lg: '1rem', md: '1rem', sm: '2vw', xs: '2vw' },
                      }}
                    >{`ID:${playerInfo?.playerName}`}</Typography>
                  </Stack>
                  <Box sx={{ width: { xl: '200px', lg: '200px', md: '200px', sm: '33vw', xs: '33vw' } }}>
                    <button
                      style={{
                        background: '#1e1e1e',
                        border: '1px solid #FFC85C',
                        borderRadius: '65px',
                        padding: '5px',
                        width: '100%',
                        marginTop: '5px',
                      }}
                    >
                      <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <Image
                          src={require('../../../assets/coin.png')}
                          alt="Coin"
                          sx={{
                            width: { xl: '25px', lg: '25px', md: '25px', sm: '4vw', xs: '4vw' },
                            height: { xl: '25px', lg: '25px', md: '25px', sm: '4vw', xs: '4vw' },
                          }}
                        />
                        <Typography
                          sx={{
                            flexGrow: 1,
                            color: 'white',
                            fontFamily: 'Digital Numbers',
                            fontWeight: 400,
                            fontSize: { xl: '1.2rem', lg: '1.2rem', md: '1.2rem', sm: '3.3vw', xs: '3.3vw' },
                          }}
                        >
                          {fCurrency(balance)}
                        </Typography>
                      </Stack>
                    </button>
                  </Box>
                </Stack>

                {/* {notifs && <NotificationsPopover notifications={notifs} readNotifications={readNotifications} />} */}
              </Stack>
            </Grid>
          </Grid>
        </RooToolbar>
      </RootStyle>
      {/* 
      - Show Win GIF dialog
      - On Click will show win summary
      <CustomDialog
        open={openWinBet}
        fullWidth
        maxWidth="lg"
        fullScreen
        disable
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            readNotifications();
            setOpenWinBet(false);
          }
        }}
        disableEscapeKeyDown
        PaperProps={{ sx: { borderRadius: '15px !important' } }}
      >
        <Box
          onClick={() => {
            readNotifications();
            setOpenWinBet(false);
            getDrawWinBetInfo();
          }}
        >
          <img src={require('../../../assets/win.gif')} alt="Win Bet" style={{ borderRadius: 15 }} />
        </Box>
      </CustomDialog> */}

      {openWinAnimation && (
        <Box sx={{ position: 'relative' }} className="win-animation-container">
          {/* eslint-disable jsx-a11y/media-has-caption */}
          <img
            src={require('../../../assets/game_selection/winner_icon_wingless.png')}
            alt="Winner"
            className="winner-animation"
            style={{ height: isSafari ? 'auto' : 'fit-content' }}
          />
          <video width="600" height="100%" autoPlay muted playsinline className="impact">
            <source src="https://appnity-public.s3.ap-southeast-1.amazonaws.com/Impact.webm" type="video/webm" />
            <source
              src="https://appnity-public.s3.ap-southeast-1.amazonaws.com/Impack.mp4"
              type='video/mp4; codecs="hvc1"'
            />
          </video>
          <Box className="left-wing">
            <img src={require('../../../assets/game_selection/left_wing.png')} alt="Left Wing" />
          </Box>
          <Box className="right-wing">
            <img src={require('../../../assets/game_selection/right_wing.png')} alt="Right Wing" />
          </Box>
        </Box>
      )}

      {standAloneWinIcon && winStyle?.y > 0 && winStyle?.x > 0 && (
        <m.img
          initial={{
            top: '50%',
            left: '50%',
            x: '-50%',
            y: '-50%',
            height: isSafari ? 'auto' : 'fit-content',
          }}
          animate={{
            top: 0,
            left: 0,
            // width: screenWidth > 350 ? '60px' : '45px',
            width: '60px',
            height: isSafari ? 'auto' : 'fit-content',
            x: winStyle.x,
            y: winStyle.y,
          }}
          transition={{ delay: 0.5, duration: 0.2, ease: 'linear' }}
          src={require('../../../assets/game_selection/winner_icon.png')}
          alt="Winner"
          className="win-icon-overlay"
          // style={winStyle}
        />
      )}

      {openDrawResult?.open && (
        <DrawResultDialog
          openDrawResult={openDrawResult}
          setOpenDrawResult={setOpenDrawResult}
          winDialogAnimate={winDialogAnimate}
          setWinIconPosition={setWinIconPosition}
          setStandAloneWinIcon={() => setStandAloneWinIcon(false)}
        />
      )}

      {/* {winBetInfo.data && (
        // <DrawResultDialog />
        <CustomDialog
          open={winBetInfo.open}
          fullWidth
          maxWidth="sm"
          fullScreen
          disable
          onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
              setWinBetInfo((prevState) => ({ open: false, data: null }));
            }
          }}
          disableEscapeKeyDown
          PaperProps={{ sx: { borderRadius: '15px !important' } }}
        >
          <Box
            sx={{
              height: 'fit-content',
              width: '30vw',
              maxWidth: '500px',
              maxHeight: '100%',
              p: 3,
              background: 'rgb(19, 19, 19)',
            }}
          >
            <Typography textAlign="center" variant="h4" sx={{ color: 'orange' }}>
              Win Summary
            </Typography>
            <Stack direction="row" sx={{ mt: 3 }}>
              <Typography sx={{ fontWeight: 700, fontSize: '1.2rem' }}>Draw Number:</Typography>
              <Typography sx={{ marginLeft: '10px', fontWeight: 700, fontSize: '1.2rem', fontStyle: 'italic' }}>
                {winBetInfo.data.drawLot}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ fontWeight: 700, fontSize: '1.2rem' }}>Draw Date:</Typography>
              <Typography sx={{ marginLeft: '10px', fontWeight: 700, fontSize: '1.2rem', fontStyle: 'italic' }}>
                {winBetInfo.data.drawDate}
              </Typography>
            </Stack>
            {winBetInfo?.data?.wons?.length > 0 &&
              winBetInfo?.data?.wons.map((item, index) => (
                <Box sx={{ mt: 3 }}>
                  <Typography sx={{ fontWeight: 700, fontSize: '1.2rem' }}>2 Toads</Typography>
                  <Stack direction="row">
                    <Typography>Bet Number:</Typography>
                    <Typography sx={{ ml: '10px', fontStyle: 'italic', fontWeight: 700 }}>{item.betNumber}</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography>Bet Amount:</Typography>
                    <Typography sx={{ ml: '10px', fontStyle: 'italic', fontWeight: 700 }}>{item.betAmount}</Typography>
                  </Stack>
                  <Stack direction="row">
                    <Typography>Win Amount:</Typography>
                    <Typography sx={{ ml: '10px', fontStyle: 'italic', fontWeight: 700, color: 'green' }}>
                      {item.winAmount}
                    </Typography>
                  </Stack>
                  <hr style={{ height: 1, border: '1px dashed orange', marginTop: '16px' }} />
                </Box>
              ))}

            <Button
              onClick={() => setWinBetInfo((prevState) => ({ open: false, data: null }))}
              sx={{
                mt: 4,
                display: 'block',
                mx: 'auto',
                background: 'linear-gradient(0deg, rgb(251, 204, 60), rgb(247, 203, 80))',
                fontWeight: 900,
                color: 'white',
                lineHeight: 1,
                fontStyle: 'italic',
                textShadow: '0px 0px 4px black',
              }}
            >
              Ok
            </Button>
          </Box>
          <Box sx={{ position: 'absolute', right: '5px', top: '5px' }}>
            <IconButton
              onClick={() => {
                setWinBetInfo((prevState) => ({ open: false, data: null }));
              }}
              sx={{ background: 'red' }}
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          </Box>
        </CustomDialog>
      )} */}
    </>
  );
}
