// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const my = {
  shoot_numbers: `နံပါတ်များ ရိုက်ထည့်ပါ`,
  round: `အလှည့်ကျ`,
  put_a_price: `ဈေးထည့်ပါ`,
  dial_numbers: `နံပါတ်`,
  choose_from_panel: `အကန့်မှ ရွေးပါ`,
  top_3: `တတိယအဆင့် `,
  toads_3: `3 Toads`,
  top_2: `ဒုတိယအဆင့် `,
  bottom_2: `ခလုပ် ၂`,
  back_3: `ကျော ၃`,
  back_2: `ကျော ၂`,
  run_on: `ဖွင့်ပါ`,
  run_down: `ကုန်ပြီ`,
  specify_numbers: `အထူးနံပါတ်များ`,
  betting_conditions: `လေးကြေး အခြေနေများ`,
  minimum_bet_per_time: `တစ်ကြိမ်လျှင် အနဲဆုံးလောင်းနိုင်သည့်ပမာဏ`,
  maximum_bet_per_time: `တစ်ကြိမ်လျှင် အများဆုံးလောင်းနိုင်သည့်ပမာဏ`,
  maximum_bet_per_number: `တစ်ကြိမ်လျှင် အများဆုံးလောင်းနိုင်သည့် နံပါတ်အရေအတွက်`,
  find_numbers: `နံပါတ်များရှာဖွေပါ`,
  reverse_numbers: `ပြောင်းပြန်နံပါတ်များ`,
  pay: `ပေး `,
  goals_19: `၁၉ ဂိုး`,
  double_number: `ထပ်ဆင့်နံပါတ် `,
  front_swipe: `ရှေ့ကိုပွတ်ဆွဲပါ`,
  back_swipe: `နောက်ကိုပွတ်ဆွဲပါ `,
  two_low: `နှစ်ဆင့်နိမ့်သည် `,
  two_tall: `နှစ်ဆင့်မြင့်သည် `,
  two_odds: `နှစ်ဆင့်ပါသည် `,
  two_even: `နှစ်ခုပါသည် `,
  delete: `ဖြတ်သည်`,
  random_numbers: `အမှတ်တမဲ့`,
  add_numbers: `နံပါတ်ထည့်ပါ`,
  enter_5_digits: `နံပါတ် ၅ ခုရိုက်ထည့်ပါ`,
  final_closing_number: `ဆုလက်ခံမည့် ခန့်မှန်းနံပါတ်များ ပိတ်ခြင်း။`,
  sum_shot_numbers: `စုစုပေါင်း ရိုက်ထည့်သည့်နံပါတ်များ`,
  place_16th: `၁၆ကြိမ်နေရာ`,
  member_shoot_number: `ရိုက်ထည့်နိုင်သည့်နံပါတ်များ`,
  place: `နေရာ`,
  list_of_guessers: `ခန့်မှန်းသူများစာရင်း`,
  look_at_duplicates: `ထပ်ခါထပ်ခါလောင်းကြေးများကိုကြည့်ခြင်း `,
  multiply_rate: `ဆပေါင်း`,
  win_rate: `ရှုံး/နိုင်စာရင်း`,
  remove_duplicates: `ထပ်ခါထပ်ခါလောင်းကြေးများဖြတ်တောက်ခြင်း`,
  all_same_prices: `ရလဒ်များ အားလုံး **တူညီ** ခြင်း`,
  credit_balance: `လက်ကျန်ငွေ`,
  total_bet: `လောင်းကြေးစုစုပေါင်း`,
  cancel_all: `အားလုံးဖြတ်သိမ်းရန်`,
  post: `ခုလောင်းရန်`,
  not_enough_balance: `သင့်လက်ကျန်ငွေမလုံလောက်ပါ`,
  no_bet_found: `သင့်လောင်းကြေးမတွေ့ရပါ`,
  place_bet_success: `အောင်မြင်စွာ အစားထိုးပြီးပါပြီ`,
  bet_history: `လောင်းခဲ့သည့်မှတ်တမ်း `,
  confirm: `အတည်ပြုပါသည်`,
  rule_title1: `ထီပေါက်ကံစမ်းမဲ`,

  rule_p1_vip: `ထီပေါက်ကံစမ်းမဲနှင့် လောင်းကြေးစည်းမျဉ်းစည်းကမ်း YiKi VIP`,
  rule_p2_vip: `တနေ့ကို ၈၈ကြိမ်ဖွင့်လှစ်ခြင်း, ၁၅ မိနစ်တိုင်း တစ်ကြိမ်ထွက်မည်`,
  rule_p3_vip: `နံနက် ၆း၀၀ နာရီမှ စတင်ကစားနိုင်ပါသည် . (နံနက် ၅း၀၀မှစပြီး ထွက်မည့် အဖြေကိုစတင်ခန့်မှန်းနိုင်ပါသည် )`,
  rule_p4_vip: `နောက်ဆုံး  ပွဲစဉ်ကို မနက် ၃း၄၅ မိနစ်မှာ ပိတ်မှာဖြစ်ပါတယ်`,

  rule_p1: `ထီပေါက်ကံစမ်းမဲနှင့် လောင်းကြေးစည်းမျဉ်းစည်းကမ်း YiKi VIP`,
  rule_p2: `အွန်လိုင်းမှာအလွယ်တကူထိုးရန်အတွက် တစ်ရက်လျှင် ၂၈၈ပတ်ဖွင့်ပြီး ၅မိနစ်တိုင်း ကံစမ်းနိုင်ပါသည်`,
  rule_p3: `နံနက် ၆း၀၀ နာရီမှ စတင်ကစားနိုင်ပါသည် . (နံနက် ၅း၀၀မှစပြီး ထွက်မည့် အဖြေကိုစတင်ခန့်မှန်းနိုင်ပါသည် )`,
  rule_p4: `နောက်ဆုံး  ပွဲစဉ်ကို မနက် ၅း၅၅ မိနစ်မှာ ပိတ်မှာဖြစ်ပါတယ်`,

  rule_p5: `မိမိထိုးချင်တဲ့နံပတ်များကို အကြိမ်ရေအကန့်အသတ်မရှိ အခမဲ့ ပေးပို့ နိုင်ပါသည်`,
  rule_p6: `16 နံပါတ်ကို ရိုက်သောအဖွဲ့ဝင်သည် ခရက်ဒစ် 24000 အခမဲ့ ခရက်ဒစ်နှင့် နံပါတ် 1 အကြွေး 12000 အခမဲ့ ရရှိမည်ဖြစ်ပြီး၊ သို့သော် အဆိုပါအချီအတွက် အနည်းဆုံး အလောင်းအစား 6000 ခရက်ဒစ်ရှိရပါမည်။`,
  rule_title2: `ထပ်ဆုံး နံပါတ်များသည် အစိုးရထီကဲ့သို့ ထိပ်တန်းရလဒ် ၃ ခုကိုခန့်မှန်းကြည့်ပါ`,
  rule_p7: `ကြိုတင်ခန့်မှန်းမှုတွင်ပါဝင်သည့်အသင်းဝင်အရေအတွက်သည် အဆင့် 20 အောက်ဖြစ်ပါက၊ မည်သို့ပင်ဆိုစေကာမူ စနစ်သည် ထိုပွဲသို့ ချက်ချင်းဝင်ရောက်လာသောသူများကို ငွေပြန်အမ်းပေးပြီး လက်ကျန်ငွေပေးမည်ဖြစ်သည်။`,
  rule_p8: `!! အကယ်၍ စနစ်ပျက်ကျခြင်း သို့မဟုတ် ပျက်သွားပါက ဆုဂဏန်းများကို ခန့်မှန်းပေးပို့ခြင်းတွင် ပါဝင်ခြင်းမပိတ်မီကာလအတွင်းတွင် အဖွဲ့ဝင်များအား စနစ်သို့ဝင်ရောက်၍မရတော့သည့်တိုင်အောင်၊ အဖွဲ့မှ ဆုမဲများကို သင့်လျော်သလို အကဲဖြတ်မည်ဖြစ်ပါသည်။`,
  rule_p9: `(အကဲဖြတ်ခြင်းမှာ အဖွဲ့၏ဆုံးဖြတ် ချက်ဖြစ်သည်။ အဖွဲ့သည် ပွင့်လင်းမြင်သာမှု အရှိဆုံး တရားမျှတမှုဖြင့် အကဲဖြတ်မည်)`,
  rule_p10: `ပါဝင်မှု အချိန်မကုန်ဆုံးမီ ၁ မိနစ်အတွင်း အနိုင်ရတဲ့ နံပါတများကို ခန့်မှန်းပေးသည့်အတွက် စနစ်တွင်းသို့ ဝင်ရောက်လို့မရနိုင်တော့ပါ ။
  ကိန်းဂဏန်းများကို ခန့်မှန်းရာတွင် သင့်အား မပါဝင်နိုင်တော့ပါက သင့်အား သွင်းငွေကို ချက်ချင်းပြန်အမ်းမည်ဖြစ်ပါသည်၊ အဖွဲ့ဝင် အားလုံးပေါင်းသည် id 20 ဉီးလိုအပ်ပြီး အနည်းဆုံး နံပါတ် ၂၀ ကို ထည့်သွင်းရေတွက်မှသာ ဂိမ်းသည်မှန်ကန်မည်ဖြစ်ပါသည်။`,
  rule_p11: `(ဆုများထုတ်ပေးခြင်းကို အသင်းဝင်တိုင်း ဂဏန်း 5 လုံး နံပါတ် [12345] ပေးပို့သည့် ဆုရလဒ်များမှ တွက်ချက်ပြီး၊ ထို့နောက် အသင်းမှ ပေးပို့သော ဂဏန်းများအားလုံးကို စုစည်းပြီး ယူလာခဲ့သည့် ပေါင်းလဒ်ကို နုတ်ယူပါမည်။ 16 ကြိမ်မြောက် နံပါတ်နှင့်အတူ ရရှိလာသော ရလဒ်များကို ဆုများအဖြစ် ထုတ်ယူသွားမည်ဖြစ်ပါသည်။`,
  rule_p12: `ဆုများထုတ်ပေးခြင်းနမူနာ ဂဏန်းရိုက်ချက်အားလုံး၏ပေါင်းလဒ်သည် 1234567890 – [51876 ဥပမာ နံပါတ် 16] = 1234516014 << ဂဏန်း 16 ကိုနုတ်ပြီးနောက် ရလဒ်ဖြစ်သည်။`,
  rule_p13: `Yiki ထီ အဝိုင်း၏ ထိပ်နံပါတ် (၃) လုံး၏ နောက်ဆုံးဂဏန်း (၃) လုံးကို ထုတ်ယူမည်ဖြစ်ပြီး၊ ထို့နောက် ရီကီထီအဝိုင်း၏ အောက်ဂဏန်း (၂) လုံးမှ ထောင်ဂဏန်းနှင့် သောင်းဂဏန်းများကို ထုတ်ယူမည်ဖြစ်သည်။`,
  rule_p14: `ဥပမာအရ၊ 16 ခုမြောက်အစီအစဥ်ကို နုတ်ပြီးနောက် ပေါင်းလဒ် = 12345[16][014] = ထိပ်တန်းအနိုင်ရဂဏန်း 3 လုံးသည် = [014] အောက်ဆုံးအနိုင်ရဂဏန်း 2 လုံးမှာ = [16] 16th နံပါတ်ကို ရိုက်နိုင်သော အဖွဲ့ဝင်ဖြစ်သည်။ အခမဲ့ ခရက်ဒစ် 24000 ရယူပါ။`,
  rule_p15: `ဂဏန်းများအထူး!! နံပါတ် ၁ ကိုရိုက်နှိပ်သောအဖွဲ့သည် အခမဲ့ဘောနပ်စ် ၁၂၀၀၀ ရရှိမည်ဖြစ်သည်`,
  rule_p16: `(အကြွေး 6000 သို့မဟုတ် ထိုထက်မကသော အဝိုင်းအတွက် အနည်းဆုံး လောင်းကြေးသတ်မှတ်ချက်များ ရှိရမည်)`,
  // rule_p17: `Lottery Yi Ki is open to bet 88 rounds per day.`,
  // rule_p18: `Give out prizes every 15 minutes.`,
  // rule_p19: `Open the first round 06:00 – 03:45 after the Yi Ki lottery closes for each round.`,
  rule_p20: `နံပါတ်ပစ်ခတ်မှုစနစ်သည် နောက်ထပ် ၁ မိနစ်ကြာပြီးနောက် နံပါတ်ပစ်ခတ်မှုစနစ်ကို ပိတ်ပြီးနောက် ၂ မိနစ်အတွင်း ပစ်ခတ်ရန်အတွက် ဖွင့်သည်။`,
  rule_p21: `ငွေပေးချေရန် 2-5 မိနစ်ကြာသည့် ကြေညာချက်ကို စနစ်က စစ်ဆေးမည်ဖြစ်သည်။`,
  bet_result: `လောင်းကြေးရလဒ်`,
  time_left: `{{x}} ကြိမ်မြောက် ဆုမဲပေါက်ရန် အချိန်ကျပါပြီ `,
  type: `ရိုက်ရန်`,
  bet_type: 'လောင်းသည့်ပုံစံ',
  result: `အဖြေ`,
  draw_date: `အဖြေထွက်သည့်နေ့ `,
  no_result: `အဖြေမထွက်ပါ`,
  bet_number: `လောင်းခဲ့သည့် နံပတ်များ`,
  amount: `ငွေအမောင့်`,
  closing_date_time: `ပိတ်မည့် နေ့စွဲ/အချိန်`,
  closed_at: `ပိတ်သည်`,
  draw_ended: `ပွဲစဉ်ပြီးချိန်`,
  refunded: `ပြန်အမ်းငွေ`,
  draw_ended_message: `သရေကျပါပြီ အဖြေကိုစောင့်ဆိုင်းပါ`,

  demo: {
    title: `Arabic`,
    introduction: `لوريم إيبسوم هو ببساطة نص شكلي يستخدم في صناعة الطباعة والتنضيد. كان Lorem Ipsum هو النص الوهمي القياسي في الصناعة منذ القرن الخامس عشر الميلادي ، عندما أخذت طابعة غير معروفة لوحًا من النوع وتدافعت عليه لعمل كتاب عينة. لقد نجت ليس فقط خمسة قرون ، ولكن أيضًا القفزة في التنضيد الإلكتروني ، وظلت دون تغيير جوهري. تم نشره في الستينيات من القرن الماضي بإصدار أوراق Letraset التي تحتوي على مقاطع Lorem Ipsum ، ومؤخرًا مع برامج النشر المكتبي مثل Aldus PageMaker بما في ذلك إصدارات Lorem Ipsum.`,
  },
  docs: {
    hi: `أهلا`,
    description: `تحتاج مساعدة؟ \n الرجاء مراجعة مستنداتنا`,
    documentation: `توثيق`,
  },
  // featured_lottery: `အထူးအသားပေးထီ`,
  // try_your_luck_today: `ဒီနေ့ မင်းကံကောင်းအောင်ကြိုးစားပါ။`,
  // play: `ကစားပါ။`,
  // twenty_four_hours: `24 နာရီ`,
  // open_bet: `Bet ကိုဖွင့်ပါ။`,
  // rounds: `ဟိုနေ့က`,
  // join_now: `ယခု ပါဝင်လိုက်ပါ။`,
  // closing_at: `ပိတ်ရန်_မှာ`,
  // not_accepting_bets: `လောင်းကစားလက်မခံပါ။`,
  // rules: `စည်း`,
  // rules_title: `หวยจับยี่กี`,
  // rules_subtitle: `หวยจับยี่กี่ กติกาการเเทง Yi Ki`,
  // rules_bullet1: `เปิดรับเเทงออนไลน์วันละ 88 รอบ ออกผลรางวัลทุกๆ 15 นาที`,
  // rules_bullet2: `รอบเเรกเริ่มเวลา 06:00น. (เริ่มทายผลใหม่ได้ตั้งเเต่ 05:00น.)`,
  // rules_bullet3: `ปิดรับเเทงรอบสุดท้าย เวลา 03:45น.`,
  // rules_bullet4: `สามารถส่งตัวเลขได้ฟรีไม่จำกัดจำนวนครั้งด้วยกติกาที่ชัดเสจเเละตรวจสอบได้`,
  // rules_bullet5: `สมาชิกที่ยิงเลขได้ ลำดับที่ 16 จะได้รับเครดิตฟรี 400 เครดิต เเละ ลำดับ 1 ฟรี 200 เครดิต เเต่จะต้องมียอดเดิมพันรอบนั้นขั้นต่ำ 100 เครดิตขั้นไป`,
  // rules_top_running_numbers: `เลขวิ่งบน รับทายผล 3 ตัวบนเหมือนหวยรัฐบาล`,
  // rules_if_number_of_members: `หากเลขที่สมาชิกร่วมทายผล มีไม่ถึง 20 ลำดับ ไม่ว่ากรณีใดก็ตาม ทางระบบจะทำการคืนโพยเเละยอดเงินที่เเทงเข้ามาในรอบนั้นทันที`,
  // rules_if_system_crashes: ` !!หากระบบล่มหรือขัดข้อง ระหว่างก่อนปิดการร่วมส่งทายผลตัวเลขออกรางวัลจนเป็นสาเหตุทำให้สมาชิกไม่สามารถเเข้าใช้งานระบบได้ทีมงานจะทำการตัดสินการออกรางวัลตามความเหมาะสม`,
  // rules_judging: `(การตัดสินขึ้นอยู่กับดุลพินิจของทีมงาน ทีมงานจะตัดสินด้วยความโปร่งใสเเละเป็นธรรมที่สุด)`,
  // rules_before_participation: `ก่อนหมดเวลาร่วมส่งผลทายผลตัวเลขออกรางวัลที่ 1 นาทีระบบเกิดปัญหาข้อข้องไม่สามารถเข้าใช้งานได้ ทำให้ท่านไม่สามารถเข้าร่วมทายผลตัวเลขได้ กรณีนี้ทางทีมงานจะทำการยกเลิกเเละคืนเงินเครดิตในรอบนั้นทีนที ถึงเเม้ว่าก่อนหน้านั้นจะมีสมาชิยิงเข้ามามากกว่า 20 ลำดับก็ตาม`,
  // rules_issuance_of_prize: `(การออกผลรางวัล คิดจาก ผลรางวัลทีสมาชิกทุกๆท่านส่งตัวเลขจำนวน 5 หลัก [12345] จากนั้นทีมงานจะนำตัวเลขที่สมาชิกส่งเข้ามาทั้งหมดในรอบนั้นๆมารวมกัน เเล้ว เอาผลรวมที่ได้นำไปลบกับเลข ลำดับที่ 16 ของรอบนั้น เเละจะนำผลที่ได้มาเป็ยผลออกรางวัล)`,
  // rules_example_of_issuance: `ตัวอย่าง การออกผลรางวัล ผลรวมการยิงเลขทั้งหมดได้ 1234567890 – [51876 ตัวอย่างลำดับที่ 16] = 1234516014 << ผลหลังจากกลบลำดับที่ 16`,
  // rules_the_total_of: `ผลรวม 3 ตัวท้าย จะถูกนำมาออกผลรางวัลเป็นเลข 3 ตัวบนของหวยยี่กี่รอบนั้นผลรวมหลักพันเเละหลักหมื่นจะถูกนำมาออกผลรางวัลเป็นเลข 2 ตัวล่าง ของหวยยี่กีรอบนั้น`,
  // rules_according_to: `ตามตัวอย่างผลรวมหลังลบลำดับที่ 16 ได้ = 12345[16][014] = เลขที่ออก 3 ตัวบนคือ = [014] เลขที่ออก 2 ตัวล่างคือ = [16] สมาชิกที่ยิงเลขได้ลำดับที่ 16 ได้รับเครดิตฟรี 400 เครดิต`,
  // rules_special: `พิเศษ!! สมาชิกที่ยิงเลขได้ลำดับที่ 1 จะได้รับเครดิตฟรี 200 เครดิต`,
  // rules_condition: `(เงื่อนไขจะต้องมียอดเดิมพันรอบนั้นขั้นต่ำ 100 เครดิต ขึ้นไป)`,
  // rules_condition1: `หวยยี่กีเปิดรับเเทงวันละ 88 รอบ`,
  // rules_condition2: `ออกผลรางวัลทุกๆ 15 นาที`,
  // rules_condition3: `เปิดรอบเเรก 06:00น. – 03:45น หลังจากหวยยี่กีปิดรับเเทงเเต่ละรอบ`,
  // rules_condition4: `ระบบยิงเลขเปิดให้ยิง 2 นาทีหลังจากระบบยิงเลขปิดอีก 1 นาที`,
  // rules_condition5: `ระบบจะทำการตรวจโพยโดยใช้เวลาการจ่ายเงิน 2 – 5 นาที`,
  // example: `ตัวอย่าง`,
  // rules_yiki_round_closing: `ยี่กี่รอบที่ 1 ปิดรับเเทง 06:00น. ยิงเลขได้ถึง 06:02น. เเละ ระบบจะตรวจสอบโพยเวลา 06:03น. เเละจ่ายเงินท่านทั้งหมดเสร็จภายในเวลาไม่เกิน 06:08น`,
  // rules_please_set_time: `** โปรดตั้งเวลาของอุปกรณ์เข้าเล่นของท่านให้ตรงกับเวลาในเว็บ เพื่อที่ท่านจะได้ไม่สับสนกับเวลาปิดรับ เวลาที่เเสดงผลการส่งตัวเลขจะใช้เวลาจากเซิฟเวอร์จริง **`,
  // rules_payment_rate: `อัตตราการจ่ายหวยยี่กี`,
  // rules_3_top: `3ตัวบนราคาจ่าย850บ`,
  // rules_3_toads: `คางคก 3 ตัว ราคา 120 เครดิต`,
  // rules_2_top_bottom: `2ตัวบน-ล่าง ราคา 92 บ`,
  // rules_running_numbers: `เลขวิ่ง 3 ตัวบน 3.2 หลัก`,
  // rules_running_numbers_bottom_2: `เลขวิ่ง2ตัวล่างราคา4.2เครดิต`,
  // rules_price_change: `*หากราคามีการเปลี่ยนเเปลง จะเเจ้งให้ทราบล่วงหน้าก่อนมีการเปลี่ยนเเปลง 7 วัน`,
  // rules_if_want_cancel: `*หากต้องการยกเลิกการแทง สามารถทำได้จนกว่าก่อนการปิดรับแทง 1 นาที`,
  // shoot_numbers: `Shoot Numbers`,
  // round: `အလှည့်ကျ`, // รอบที่
  // pull_a_note: `Pull a note`,
  // put_a_price: `Put a price`,
  // stab_list: `Stab List`,
  // dial_numbers: `Dial Number`,
  // choose_from_panel: `Choose from panel`,
  // top_3: `တတိယအဆင့်`,
  // toads_3: `3 Toads`,
  // top_2: `Top 2`,
  // bottom_2: `ခလုပ် ၂`,
  // back_3: `Back 3`,
  // back_2: `Back 2`,
  // run_on: `Run On`,
  // run_down: `Run Down`,
  // selected_items: `Selected item(s)`,
  // specify_numbers: `Specify Numbers`,
  // cancel_latest: `Cancel Latest`,
  // clear_data: `Clear Data`,
  // betting_conditions: `Betting Conditions`,
  // minimum_bet_per_time: `Minimum bet per time: `,
  // maximum_bet_per_time: `Maximum bet per time: `,
  // maximum_bet_per_number: `Maximum bet per number: `,
  // three: `Three`,
  // two: `Two`,
  // run_number: `Run Number`,
  // find_numbers: `find numbers`,
  // reverse_numbers: `Reverse Numbers`,
  // pay: `Pay: `,
  // more_options: `more options`,
  // goals_19: `19 GOALS`,
  // double_number: `DOUBLE NUMBER`,
  // front_swipe: `front swipe`,
  // back_swipe: `back swipe`,
  // two_low: `two low`,
  // two_tall: `two tall`,
  // two_odds: `two odd`,
  // two_even: `two even`,
  // lottery_list: `Lottery List`,
  // random_numbers: `Random`,
  // add_numbers: `Add Numbers`,
  // enter_5_digits: `Enter 5 digits`,
  // final_closing_number: `Closing acceptin prize prediction numbers`,
  // sum_shot_numbers: `Sum shot numbers`,
  // place_16th: `16th place`,
  // member_shoot_number: `Members can shoot numbers.`,
  // place: `place`,
  // list_of_guessers: `List of guessers`,
  // look_at_duplicates: `Look at repeating numbers`,
  // multiply_rate: `Multiply Rate`,
  // win_rate: `Win Amount`,
  // remove_duplicates: `Cut Repeating numbers`,
  // all_same_prices: `All "same" prices`,
  // credit_balance: `Credit Balance`,
  // total_bet: `Total Bet`,
  // cancel_all: `Cancel All`,
  // post: `Post`,
  // you_have: `You have`,
  // unread_notification: `unread notification(s)`,
  // not_enough_balance: `Not enough balance`,
  // no_bet_found: `No bet found`,
  // place_bet_success: `Place bet success!`,
  // bet_history: `Bet History`,
  // confirm: `Confirm`,
  // past_result: `Past Result`,

  app: `تطبيق`,
  user: `المستعمل`,
  list: `قائمة`,
  edit: `تعديل`,
  shop: `متجر`,
  blog: `مقالات`,
  mail: `بريد`,
  chat: `دردشة`,
  cards: `البطاقات`,
  posts: `المشاركات`,
  create: `يزيد`,
  kanban: `كانبان`,
  general: `جنرال لواء`,
  banking: `الخدمات المصرفية`,
  booking: `الحجز`,
  profile: `الملف الشخصي`,
  account: `الحساب`,
  product: `المنتوج`,
  invoice: `فاتورة`,
  details: `تفاصيل`,
  checkout: `الدفع`,
  calendar: `التقويم`,
  analytics: `التحليلات`,
  ecommerce: `التجارة الإلكترونية`,
  management: `إدارة`,
  menu_level_1: `مستوى القائمة 1`,
  menu_level_2: `مستوى القائمة 2`,
  menu_level_3: `مستوى القائمة 3`,
  item_disabled: `العنصر معطل`,
  item_label: `تسمية العنصر`,
  item_caption: `عنوان العنصر`,
  description: `وصف`,
  other_cases: `حالات اخرى`,
  item_by_roles: `عنصر حسب الأدوار`,
  only_admin_can_see_this_item: `يمكن للمسؤول فقط رؤية هذا العنصر`,
};

export default my;
