// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  shoot_numbers: `ยิงเลข`,
  round: `รอบที่`,
  put_a_price: `ใส่ราคา`,
  dial_numbers: `กดเลขเอง`,
  choose_from_panel: `เลือกจากแผง`,
  top_3: `3ตัวบน`,
  toads_3: `3ตัวโต๊ด`,
  top_2: `2ตัวบน`,
  bottom_2: `2ตัวล่าง`,
  back_3: `3ตัวกลับ`,
  back_2: `2ตัวกลับ`,
  run_on: `วิ่งบน`,
  run_down: `วิ่งล่าง`,
  specify_numbers: `ระบุตัวเลข`,
  betting_conditions: `เงื่อนไขการแทง`,
  minimum_bet_per_time: `แทงขั้นต่ำต่อครั้ง: `,
  maximum_bet_per_time: `แทงสูงสุดต่อครั้ง: `,
  maximum_bet_per_number: `แทงสูงสุดต่อเลข: `,
  find_numbers: `ค้นหาตัวเลข`,
  reverse_numbers: `กลับตัวเลข`,
  pay: `จ่าย: `,
  goals_19: `19 ประตู`,
  double_number: `เลขเบิ้ล`,
  front_swipe: `รูดหน้า`,
  back_swipe: `รูดหลัง`,
  two_low: `สองตัวต่ำ`,
  two_tall: `สองตัวสูง`,
  two_odds: `สองตัวคี่`,
  two_even: `สองตัวคุ่`,
  delete: `ลบ`,
  random_numbers: `สุ่มตัวเลข`,
  add_numbers: `เพิ่มหมายเลข`,
  enter_5_digits: `ใส่ตัวเลข 5 หลัก`,
  final_closing_number: `ปิดรับการทายผล`,
  sum_shot_numbers: `ผลรวมการยิงเลข`,
  place_16th: `เลขลำดับที่ 16`,
  member_shoot_number: `สมาชิกยิงเลขได้.`,
  place: `อันดับ`,
  list_of_guessers: `รายชื่อผู้ทายเลข`,
  look_at_duplicates: `ดูเลขซ้ำ`,
  multiply_rate: `อัตราคูณ`,
  win_rate: `จำนวนเงินชนะ/แพ้`,
  remove_duplicates: `ตัดเลขซ้ำ`,
  all_same_prices: `ราคา"เท่ากัน"ทั้งหมด`,
  credit_balance: `ยอดเครดิตคงเหลือ`,
  total_bet: `รวมยอดแทง`,
  cancel_all: `ยกเลิกทั้งหมด`,
  post: `ส่งโพย`,
  not_enough_balance: `เครดิตไม่เพียงพอ`,
  no_bet_found: `ไม่พบการทายผล`,
  place_bet_success: `ทายผลสำเร็จ`,
  bet_history: `ประวัติการทายผล`,
  confirm: `ยืนยัน`,
  rule_title1: `รางวัลล็อตเตอรี่มหาสนุก`,

  rule_p1_vip: `นาทีทอง หวยยี่กี VIP (ประกาศผลในทุกๆ 15 นาที)`,
  rule_p2_vip: `เปิดรับเดิมพัน 88 รอบต่อวัน`,
  rule_p3_vip: `เดิมพันรอบแรกของวันเริ่ม 06.00 น.`,
  rule_p4_vip: `(สามารถเริ่มทายผลได้ตั้งแต่เวลา 05.00 น. และ ปิดรับรอบสุดท้าย 05.55 น.)`,

  rule_p1: `นาทีทอง หวยยี่กี (ประกาศผลในทุกๆ 5 นาที)`,
  rule_p2: `เปิดรับเดิมพัน 288 รอบต่อวัน `,
  rule_p3: `เดิมพันรอบแรกของวันเริ่ม 6.00 น. (สามารถเริ่มทายผลได้ตั้งแต่เวลา 5.00 น.)`,
  rule_p4: `ปิดรับรอบสุดท้าย 5.55 น.`,

  
  rule_p5: `สามารถส่งตัวเลขได้ไม่จำกัดจำนวนครั้ง แต่ต้องเป็นไปตามกฏที่ระบุไว้`,
  rule_p6: `ผู้โชคดีที่ยิงเลขลำดับที่ 16 รับทันที 400 เครดิต และ ผู้โชคดีลำดับที่ 1 รับรางวัล 200 เครดิต ทั้งนี้ ยอดซื้อขั้นต่ำต้องไม่น้อยกว่า 100 เครดิตในรอบนั้น ๆ`,

  rule_title2: `การรันตัวเลข ผลลัพท์ 3 ตัว จะออกรางวัลเหมือนเล่นล็อตเตอรี่รัฐบาล`,
  rule_p7: `หากมีการยิงตัวเลขไม่ถึง 20 หมายเลข ระบบจะทำการยกเลิกการออกรางวัลในรอบนั้นทันที และคืนยอดให้ผู้เล่นทุกท่าน`,
  rule_p8: `หากระบบเกิดการล่ม ระหว่างการออกรางวัล ทางเว็บจะออกผลรางวัลให้ตามความเหมาะสม.`,
  rule_p9: `(การตัดสินขึ้นอยู่กับดุลยพินิจของทีมงาน ทีมงานจะตัดสินด้วยความโปร่งใสและยุติธรรมที่สุด)`,
  rule_p10: `หากระบบมีปัญหาไม่สามารถเข้าร่วมทายผลได้ ในกรณีนี้ทีมงานจะยกเลิกและคืนเครดิตในรอบนั้นทันที แม้ว่าก่อนหน้านั้นจะมีสมาชิกยิงกันมากกว่า 20 คนตามลำดับก็ตาม.`,
  rule_p11: `(การออกรางวัลจะคำนวณจากผลรางวัลที่สมาชิกทุกคนส่งหมายเลข 5 หลัก [12345] จากนั้นทีมงานจะรวบรวมหมายเลขที่สมาชิกส่งมาในรอบนั้นทั้งหมดแล้วนำ
   ตัวเลขลบออกด้วยเลขที่ลำดับที่ 16 ของรอบนั้นส่งเข้ามา ออกเป็นผลการออกรางวัล)`,
  rule_p12: `ตัวอย่างการออกรางวัล ถ้าผลรวมของเลขยิงทั้งหมดออกมาเป็น 123456789-[ตัวเลขของลำดับที่ 16 ที่ยิงเข้ามา *51876*]= 1234516014 เลขที่ถูกรางวัลคือ 516014.`,
  rule_p13: `ตัวเลข 3 ตัวท้ายจะออกเป็นรางวัลเลขท้าย 3 ตัว และเลขในลำดับที่ 4 และ 5 จะออกเป็นผลรางวัลเลขหน้า 2 ตัว.`,
  rule_p14: `ยกตัวอย่างเช่นเลขที่ออกรางวัลคคือ 12345[16][014] เลขท้าย 3 ตัวเท่ากับ [014] เลขหน้า 2 ตัวเท่ากับ [16]`,
  rule_p15: `พิเศษ!! ผู้ที่ยิงเลขเข้ามาเป็นลำดับที่ 1 ของรอบจะได้รับเครดิตฟรีทันที 200 เครดิต.`,
  rule_p16: `(เงื่อนไขจะต้องมีเดิมพันขั้นต่ำสำหรับรอบนั้น 100 เครดิตขึ้นไป)`,
  // rule_p17: `ล็อตเตอรี่ยี่กี เปิดให้ทายผลมากถึง 88 รอบต่อวัน.`,
  // rule_p18: `ออกรางวัลทุก 15 นาที.`,
  // rule_p19: `เริ่มรอบแรก 6.00 นาฬิกา ถึง 3.45 นาฬิกา ของทุกวัน.`,
  rule_p20: `ระบบการยิงเลขจะเปิดให้ยิง 2 นาทีหลังจากการเปิดให้ทายผล และปิดก่อน 1 นาทีก่อนการจับรางวัล.`,
  rule_p21: `ระบบจะตรวจสอบผลการออกรางวัลและแจ้งยอดในเวลา 2-5 นาที หลังจบการออกรางวัล.`,
  bet_result: `ผลการออกรางวัล`,
  time_left: `เวลาก่อนการออกรางวัล รอบที่ {{x}}`,
  type: `ประเภท`,
  bet_type: 'ประเภทการเดิมพัน',
  result: `ผลลัพท์`,
  draw_date: `วันที่ทายผล`,
  no_result: `ไม่พบผลลัพท์`,
  bet_number: `หมายเลขเดิมพัน`,
  amount: `จำนวน`,
  closing_date_time: `วัน/เวลา สิ้นสุด`,
  closed_at: `ปิดทำการ ณ`,
  draw_ended: `ออกรางวัล?`,
  refunded: `คืนเงินแล้ว?`,
  draw_ended_message: `การจับสลากสิ้นสุดลงแล้ว กรุณารอผล.`,

  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },

  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level_1: `menu level 1`,
  menu_level_2: `menu level 2`,
  menu_level_3: `menu level 3`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
};

export default en;
